import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  class: "custom-select mb-2 form-control",
  disabled: ""
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "scopes-container" }
const _hoisted_8 = {
  key: 1,
  class: "scopes-info"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_check_box = _resolveComponent("tree-check-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.detail.form.name")), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.applicationName) = $event)),
        disabled: _ctx.isSavingApplication
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.applicationName]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.detail.form.company")), 1),
      _createElementVNode("div", null, [
        _createElementVNode("select", _hoisted_5, [
          _createElementVNode("option", null, _toDisplayString(_ctx.companyName), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.detail.form.scopes")), 1),
      _createElementVNode("div", _hoisted_7, [
        (!_ctx.isFetchingScopes && _ctx.scopes.length === 0)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "#",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchScopes()))
            }, "Show scopes"))
          : _createCommentVNode("", true),
        (_ctx.scopes.length === 0 || _ctx.isFetchingScopes)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.isFetchingScopes)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[3] || (_cache[3] = [
                    _createElementVNode("span", {
                      class: "spinner-border spinner-border-sm",
                      role: "status",
                      "aria-hidden": "true"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_tree_check_box, {
          items: _ctx.scopes,
          disabled: true
        }, null, 8, ["items"])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary field-submit",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateHmacAuthApplication && _ctx.updateHmacAuthApplication(...args)))
      }, _toDisplayString(_ctx.isSavingApplication
            ? _ctx.$t("pages.update_hmac_auth_application.form.button.saving")
            : _ctx.$t("pages.update_hmac_auth_application.form.button.update")), 1)
    ])
  ]))
}